import { Injectable } from '@angular/core';
import { SESSION_VAR_STATE } from '../app.constants';


export interface State {
    page: String,
    state: any
}

@Injectable({
    providedIn: 'root'
})

export class StateManagerService {

    private states: State[] = [];
    constructor() { }

    /**
     * Save state 
     * @param page 
     * @param state 
     */
    setState(page: string, state: any): void {
        
        this.loadStates();

        const index = this.states.findIndex(item => item.page === page);

        if (state !== undefined && state != null) {

            if (index !== -1) {
                this.states[index].state = state;
            } else {
                var st: State = { page: page, state: state };
                this.states.push(st);
            }
        }else{
            if (index !== -1) {
                this.states.splice(index,1);
            }
        }
        sessionStorage.setItem(SESSION_VAR_STATE, JSON.stringify(this.states));
    }

    /**
     * Retuens the state
     * @param page 
     * @returns 
     */
    getState(page: string): any {
        this.loadStates();
        return this.states.find((p: any) => p.page == page);
    }

    /**
     * Load the previous states from session
     */
    private loadStates(): any {
        var states: any = sessionStorage.getItem(SESSION_VAR_STATE);
        if (states != undefined && states !== null) {
            this.states = JSON.parse(states);
        } else {
            this.states = [];
        }
    }
    resetState(): any {
        this.states = [];
        sessionStorage.setItem(SESSION_VAR_STATE, JSON.stringify(this.states));

    }



}