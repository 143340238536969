import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestData } from 'src/app/common/models/request-data.model';
import { environment } from 'src/environments/environment';
import { RewardModel } from './model/reward.model';
import { DiscountModel } from '../discount/model/discount.model';
import { FilterListItemModel } from 'src/app/common/components/search-filter/search-filter.component';


@Injectable({
  providedIn: 'root'
})
export class RewardService {
  uploadImage(selectedFile: File) {
    throw new Error('Method not implemented.');
  }

    // Properties to hold the selected data
    selectedCategories: string[] = [];
    selectedProducts: string[] = [];
    
  updateRewardSegments(rewardInfo: RewardModel) {
    throw new Error('Method not implemented.');
  }

  constructor(private http: HttpClient) { }
  
  /**
   * Update the selected categories in the service
   */
  updateSelectedCategories(categories: string[]): void {
    this.selectedCategories = categories;
  }

  /**
   * Update the selected products in the service
   */
  updateSelectedProducts(products: string[]): void {
    this.selectedProducts = products;
  }

  /**
   * 
   * @returns The list of reward
   * Calls the API to get the list of reward from server
   */
  getList(requestData:RequestData): Observable<any> {

   
      return this.http.post(environment.baseUrl+'/reward/get', requestData);
    
  }
  /**
   * 
   * @returns Uploaded Image
   * Calls the API to UploadImage of reward from server
   */
  getImage(file: File,id:any): Observable<any> {
    const formData = new FormData();
    formData.append('reward_image', file);
    formData.append('reward_id', id);


    return this.http.post(environment.baseUrl+'/reward/uploadImage', formData);
  }
  getRewardById(id:number): Observable<any> {
    return this.http.post(environment.baseUrl+'/reward/get/'+id, '');

  }
  publishReward(id:number): Observable<any> {
    return this.http.post(environment.baseUrl+'/reward/publishReward/'+id, '');
  }
  /**
   * 
   * @param itemInfo 
   * Calls the update API to insert/update the record
   */
  update(itemInfo: RewardModel): Observable<any> { 
    if(itemInfo.hasOwnProperty("id") && itemInfo.id!==0 ){
      return this.http.post(environment.baseUrl+'/reward/update', itemInfo);
    } else{
      return this.http.post(environment.baseUrl+'/reward/add', itemInfo);
    }
  }
   /**
   * 
   * @param discInfo 
   * Calls the update API to insert/update the record
   */
   updateDiscount(discInfo: DiscountModel): Observable<any> { 
    if(discInfo.hasOwnProperty("id")){
      return this.http.post(environment.baseUrl+'/discounts/update', discInfo);
    } else{
      return this.http.post(environment.baseUrl+'/discounts/add', discInfo);
    }
  }

  /**
   * 
   * @param itemInfo 
   * Calls the API to delete the record
   */
  delete(itemInfo: RewardModel): Observable<any>  {
    return this.http.post(environment.baseUrl+'/reward/delete/'+itemInfo.id, itemInfo);
  }
    /**
   * 
   * @param itemInfo 
   * Calls the API to delete the record
   */
 
    deleteImage(id:number): Observable<any> {
      return this.http.post(environment.baseUrl+'/reward/deleteImage/'+id, '');
  
    }

  getCategories(): Observable<any>{
    let search={"search":{"filters":{"column":[],"scope":{"limit":10,"offset":0}},"sort":[]}};
    return this.http.post(environment.baseUrl+'/reward_category/get', search);
  }
  getMailTemplateList(requestData:RequestData): Observable<any>{
    return this.http.post(environment.baseUrl+'/reward/getMailTemplates', requestData);
  }
  getEvents(): Observable<any>{
    let search={"search":{"filters":{"column":[],"scope":{"limit":10,"offset":0}},"sort":[]}};
    return this.http.post(environment.baseUrl+'/system_event/get', search);
  }
  
  getItemCategories(requestData:RequestData): Observable<any>{
    return this.http.post(environment.baseUrl+'/item_classes/get', requestData);
  }
  getProducts(requestData:RequestData): Observable<any>{
    return this.http.post(environment.baseUrl+'/sale_items/get', requestData);
  }
  getCustomerSegments(requestData:RequestData): Observable<any> {
    return this.http.post(environment.baseUrl+'/segment/get', requestData);

  }
  getDiscount(requestData:RequestData): Observable<any> {
    return this.http.post(environment.baseUrl+'/discounts/get', requestData);

  }
  downloadExcel(requestData: any): Observable<Blob> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   // const url = 'http://yourdomain.com/api/customers/export'; // Adjust URL

    return this.http.post(environment.baseUrl + '/reward/export', requestData, { headers, responseType: 'blob' })
      .pipe(
        //catchError(this.handleError)
      );

  }

  
  /**
   * Define the customer filter criteria
   * @returns 
   */
  getFilter():FilterListItemModel[]{
    return  [
      
      {
        title: "Recency(RFM)",
        column: "recency",
        type: "number"
      },
      {
        title: "Frequency(RFM)",
        column: "frequency",
        type: "number"
      },
      {
        title: "Amount(RFM)",
        column: "monetary",
        type: "number"
      },
      {
        title: "Order Count",
        column: "frequency",
        type: "number"
      },
      {
        title: "Basket Size",
        column: "monetary",
        type: "number"
      },
      {
        title: "Day Part",
        column: "top_day_part",
        type: "list",
        operators: [
          { title: "is", operator: "=" },
          { title: "is not", operator: "!=" }
        ],
        values: [
          { title: "BREAKFAST", value: "BREAKFAST" },
          { title: "LUNCH", value: "LUNCH" },
          { title: "TEA", value: "TEA" },
          { title: "DINNER", value: "DINNER" },
          
        ]
      },
      {
        title: "Member Since",
        column: "mem_since",
        type: "string"
      },
      {
        title: "First Name",
        column: "first_name",
      },
      {
        title: "Last Name",
        column: "last_name",
      },
      {
        title: "Phone",
        column: "phone_number",
        type: "string"
      },
      {
        title: "Email",
        column: "email",
        type: "string"
      },
      {
        title: "Birth Month",
        column: "dob_month",
        //type: "number",
        operators: [
          { title: "is", operator: "=" },
          { title: "is not", operator: "!=" }
        ],
        values: [
          { title: "January", value: "01" },
          { title: "February", value: "02" },
          { title: "March", value: "03" },
          { title: "April", value: "04" },
          { title: "May", value: "05" },
          { title: "June", value: "06" },
          { title: "July", value: "07" },
          { title: "August", value: "08" },
          { title: "September", value: "09" },
          { title: "October", value: "10" },
          { title: "November", value: "11" },
          { title: "December", value: "12" },
        ]
      },
  
      {
        title: "Birth Day",
        column: "dob_day",
        operators: [
          { title: "is", operator: "=" },
          { title: "is not", operator: "!=" }
        ],
        values: [
          { title: "1", value: "1" },
          { title: "2", value: "2" },
          { title: "3", value: "3" },
          { title: "4", value: "4" },
          { title: "5", value: "5" },
          { title: "6", value: "6" },
          { title: "7", value: "7" },
          { title: "8", value: "8" },
          { title: "9", value: "9" },
          { title: "10", value: "10" },
          { title: "11", value: "11" },
          { title: "12", value: "12" },
          { title: "13", value: "13" },
          { title: "14", value: "14" },
          { title: "15", value: "15" },
          { title: "16", value: "16" },
          { title: "17", value: "17" },
          { title: "18", value: "18" },
          { title: "19", value: "19" },
          { title: "20", value: "20" },
          { title: "21", value: "21" },
          { title: "22", value: "22" },
          { title: "23", value: "23" },
          { title: "24", value: "24" },
          { title: "25", value: "25" },
          { title: "26", value: "26" },
          { title: "27", value: "27" },
          { title: "28", value: "28" },
          { title: "29", value: "29" },
          { title: "30", value: "30" },
          { title: "31", value: "31" },
        ]
  
      },
      {
        title: "Gender",
        column: "gender",
        type: 'string',
        operators: [
          { title: "is", operator: "=" },
          { title: "is not", operator: "!=" }
        ],
        values: [
          { title: "Male", value: "m" },
          { title: "Female", value: "f" },
        ]
      },
      {
        title: "Active",
        column: "is_valid",
        type: "boolean",
        operators: [
          { title: "is", operator: "=" },
          { title: "is not", operator: "!=" }
        ],
        values: [
          { title: "Active", value: "1" },
          { title: "Not Active", value: "0" },
        ]
      },
      {
        title: "Type",
        column: "customer_type",
        type: "list",
        operators: [
          { title: "is", operator: "=" },
          { title: "is not", operator: "!=" }
        ],
        values: [
          { title: "Guest", value: "2" },
          { title: "Registered", value: "1" },
        ]
      },
    ];
  }
  

}
