import { LuckyDrawService } from '../../lucky-draw.service';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import Quill from 'quill';
import QuillResizeModule from '@botom/quill-resize-module';
import { LuckyDrawModel } from '../../model/lucky-draw.model';
Quill.register("modules/resize", QuillResizeModule);
@Component({
  selector: 'app-html-edit',
  templateUrl: './html-edit.component.html',
  styleUrl: './html-edit.component.scss'
})
export class HtmlEditComponent {
  quillEditor: any;
  luckyDrawInfoModel: LuckyDrawModel = {
    id: 0,
    code: "",
    name: "",
    description: "",
    short_description: "",
    entry_url: "",
    pos_receipt_text: "",
    oo_receipt_text: "",
    tc_info: "",
    privacy_policy: "",
    start_date: undefined,
    end_date: undefined,
    extra_info: {
      channel: undefined,
      customer: 2,
      min_order_amount: 0
    },
    is_deleted: 0,
    is_valid: 1,
    is_published: 0,
    brevo_template_id: undefined
  }
  isPublished: boolean;

  editorConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],        // Blockquote and code block buttons
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'script': 'sub' }, { 'script': 'super' }], // Subscript, superscript
      [{ 'color': [] }, { 'background': [] }],
      ['link', 'image', 'video'],
      // ['clean'] 
    ],
    theme: 'snow'
  };

  constructor(
    private builder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<HtmlEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    // private luckyDrawService: CompanyService,// Inject your RewardService
    private snackBarService: SnackBarService,
    private luckyDrawService: LuckyDrawService
  ) {
    // console.log(this.data);
    this.isPublished=(this.data.infoData.is_published==1)?false:true;
  }

  ngOnInit(): void {

  }

  actionClose(): void {
    this.dialogRef.close();
  }
  actionCancel(): void {
    this.dialogRef.close();
  }
  actionSave(): void {
    const data = { title: this.data.params, data: this.data.data };
    if(this.data.params=='terms'){
      this.data.infoData.tc_info = this.data.data;
    }
    if(this.data.params=='privacy'){
      this.data.infoData.privacy_policy = this.data.data;
    }
    this.luckyDrawService.update(this.data.infoData).subscribe(
      (response: any) => {
        if (response.status == "SUCCESS") {
          let snckBarData: SnackBarData = {
            type: 'success',
            title: 'Saved',
            message: 'Details updated  successfully.',
          };
          this.snackBarService.openSnackBar(snckBarData);
          this.dialogRef.close();
        }
      },
      (error: any) => {
        let snackBarData: SnackBarData = {
          title: 'Failed!!!',
          type: 'error',
          message: 'Failed to Update'
        };
        this.snackBarService.openSnackBar(snackBarData);
        this.dialogRef.close();
      }
    );
  }

}
