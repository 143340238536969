import { Injectable } from '@angular/core';
import { AppConstants } from '../common/app.constants'
import { StorageService } from '../common/services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {


    /**
     * Additional page maping
     */
    pagePermissionMaping: any[] = [
        { page: "customer-edit", parent: "customer" },
        { page: "reward-edit", parent: "reward" },
        { page: "lucky-draw-edit", parent: "lucky-draw" },

    ];

    constructor(private storageService: StorageService) { }


    /**
     * Returns the page permission for currenmtly loged in user
     * @param page 
     * @returns 
     */
    getPagePermission(page: string): any {

        let sesUser = this.storageService.loadFromSession(AppConstants.SESSION_VAR_USER);
        const users = (sesUser !== null) ? JSON.parse(sesUser) : { id: 0 };
        const permissionArr = users.permissions;
        const pagePermission = permissionArr.find((perm: any) => perm.page === page);
        return (pagePermission) ? pagePermission.permission : this.getDefaultPermission(page);
    }

    /**
     * The default permission is to view the content
     * @returns 
     */
    getDefaultPermission(page:string): any {
        var permission:any= {
            "can_view": 0,
            "can_add": 0,
            "can_edit": 0,
            "can_delete": 0,
            "can_execute": 0,
            "can_publish": 0,
            "can_export": 0,
            "publish_status": 0
        }

        const parentPage=this.pagePermissionMaping.find((pg:any)=> pg.page==page);
        if (parentPage){
            permission=this.getPagePermission(parentPage.parent);
        }
        return permission;
    }

    /**
     * Returns the permission set for the action
     * @param page 
     * @param action 
     * @returns 
     */
    hasPermission(page: string, action: string): boolean {

        const permission = this.getPagePermission(page);

        var hasPermission = 0;
        switch (action) {
            case 'view':
                hasPermission = permission.can_view;
                break;
            case 'add':
                hasPermission = permission.can_add;
                break;
            case 'edit':
                hasPermission = permission.can_edit;
                break;
            case 'delete':
                hasPermission = permission.can_delete;
                break;
            case 'execute':
                hasPermission = permission.can_execute;
                break;
            case 'publish':
                hasPermission = permission.can_publish;
                break;
            case 'export':
                hasPermission = permission.can_export;
                break;
        }
        return (hasPermission == 1) ? true : false;
    }



}