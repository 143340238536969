<div class="edit-page-root-container reward-edit-root-container">
  <div class="page-content">
    <div class="page-header ">
      <button mat-button class="nav-button" (click)="navBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="title">
        {{ getTitle() }}
      </div>
      <!-- <div class="action-btn-container ">
        <button mat-flat-button class="action-btn btn-discard"  [hidden]="!isStatusZero" (click)="navBack()">
          DISCARD
        </button>
        <button mat-flat-button class="action-btn btn-save"  [hidden]="!isStatusZero" (click)="save()">
          SAVE
        </button>
      </div> -->
      <div class="action-btn-container">
        <button mat-flat-button class="action-btn btn-browse" (click)="publishReward(rewardInfoModel.id)"
          *ngIf="allowPublishManually == true && isStatusNotOneOrTwo" [hidden]="!isStatusNotOneOrTwo">
          PUBLISH
        </button>
        <button mat-flat-button class="action-btn btn-discard"  (click)="navBack()" *ngIf="isStatusNotOneOrTwo" [hidden]="!isStatusNotOneOrTwo">
          DISCARD
        </button>
        <button mat-flat-button class="action-btn btn-save" (click)="save()" *ngIf="isStatusNotOneOrTwo" [hidden]="!isStatusNotOneOrTwo">
          SAVE
        </button>
      
      </div>

    </div>
    <!-- Add a notification element at the top -->
    <div id="notification" class="notification"
      style="display: none; text-align: center; margin-top:10px; background: yellow; color:red; padding:15px;">
      This reward has already been published. You cannot edit anymore
    </div>
    <div class="page-details">

      <!-- general info -->
      <div class="detail-section section-general-info">
        <form class="edit-form" [formGroup]="editForm">
          <div class="content-container">
            <div class="section-partition">
              <div class="partition-left">
                <mat-form-field class="code">
                  <mat-label>Code</mat-label>
                  <input matInput formControlName="code" [attr.maxlength]="10"
                    [readonly]="rewardInfoModel.id!==undefined && rewardInfoModel.id!==0" pattern="[a-zA-Z0-9\s\W]*" />
                  <mat-error [@if]="editForm.controls['code'].hasError('required')">
                    Code should be entered</mat-error>
                </mat-form-field>

                <mat-form-field class="name">
                  <mat-label>Name</mat-label>
                  <input matInput formControlName="name" [attr.maxlength]="50">
                  <mat-error
                    *ngIf="editForm.controls['name'].hasError('required') && !editForm.controls['name'].value">Name
                    should be entered</mat-error>

                </mat-form-field>
              </div>
              <div class="partition-right reward-image">

                @if(this.rewardInfoModel.image_file_name!==''){
                <img
                  src="{{this.assetsUrl}}/images/reward/{{this.rewardInfoModel.image_file_name}}?_={{this.refreshTime}}"
                  class="image-preview" />
                }
                <a mat-icon-button class="img-action-menu" [matMenuTriggerFor]="imgActionMenu">
                  <mat-icon>more_vert</mat-icon>
                </a>
                <mat-menu #imgActionMenu>
                  <button mat-menu-item (click)="selectImage()">Change</button>
                  <button mat-menu-item (click)="deleteImage(rewardInfoModel.id)">Delete
                  </button>
                </mat-menu>
              </div>


            </div>



            <mat-form-field class="description">
              <mat-label>Description</mat-label>
              <textarea matInput formControlName="description" [attr.maxlength]="255"></textarea>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Short Description</mat-label>
              <textarea matInput formControlName="short_description" [attr.maxlength]="100"></textarea>
            </mat-form-field>


            <div class="category-container">
              <mat-form-field style="width:80%;">
                <mat-label>Category</mat-label>
                <mat-select placeholder="Category" formControlName="crm_reward_category_id">
                  @for (category of rewardCategoryList; track category.id) {
                  <mat-option [value]="category.id">
                    {{ category.name }}
                  </mat-option>
                  }
                </mat-select>
                <mat-error [@if]="editForm.controls['crm_reward_category_id'].hasError('required')">RewardCategory
                  should be selected.</mat-error>
              </mat-form-field>


            </div>
          </div>
        </form>
        <div class="show-status" style="display:flex; gap:10px;">
          <mat-checkbox [(ngModel)]="rewardInfoModel.show_in_offers">Show In Offers</mat-checkbox>
          <mat-checkbox [(ngModel)]="rewardInfoModel.show_special_offer_badge">Show Special Offer Badge</mat-checkbox>

        </div>
      </div>
      <!-- general info -->

      <!--  Reward Activation -->

      <!-- Customer eligibility selection -->
      <div class="detail-section  section-customer-eligibility">
        <div class="sub-title">Targets</div>
        <mat-radio-group [(ngModel)]="rewardInfoModel.reward_eligibility" (change)="onRewardEligibilityChange($event)">
          <mat-radio-button [value]=1>Public (Offer)</mat-radio-button><br />
          @if(rewardInfoModel.reward_eligibility === 1){
          <div style="margin-left:43px;">
            <div style="display: flex; align-items: center;">
              <span class="external-title">Use By</span>
              @for (mode of acessModesReward; track acessModesReward) {
              <mat-checkbox [(ngModel)]="mode.selected" [disabled]="mode.disabled">{{mode.title}}</mat-checkbox>
              }

            </div>
            <div style="display: flex; align-items: center;">
              <span class="external-title">External</span>
              <mat-checkbox [(ngModel)]="rewardInfoModel.is_external" (change)="onExternalChange()"></mat-checkbox>
              <mat-label style="padding-left: 4px;  font-weight:500">URL</mat-label>&nbsp;&nbsp;
              <input [disabled]="!rewardInfoModel.is_external" class="external-url"
                [(ngModel)]="rewardInfoModel.external_url" required>
            </div>
          </div>
          }


          <div
            [ngStyle]="{'visibility': (rewardInfoModel.id == undefined || rewardInfoModel.id == 0) ? 'hidden' : 'visible'}">
            <div class="segment-selection">
              @if(rewardInfoModel.reward_eligibility == 2){
              <mat-radio-button [value]=2>Registered Customers (Reward)</mat-radio-button>
              }
              @if(rewardInfoModel.reward_eligibility == 2){
              <div class="action-btn-container">
                <button mat-flat-button class="action-btn btn-browse" (click)="openCustFilter()">
                  FILTER
                </button>
              </div>
              }
            </div>
          </div>

        </mat-radio-group>
        @if(rewardInfoModel.reward_eligibility == 1){
        <div></div>
        }
        @if(rewardInfoModel.reward_eligibility == 2){
        <div
          [ngStyle]="{'visibility': (rewardInfoModel.id == undefined || rewardInfoModel.id == 0) ? 'hidden' : 'visible'}">
          <mat-form-field style="width:80%; margin-top:20px;">
            <mat-select placeholder="Mail Template" [(ngModel)]="rewardInfoModel.brevo_mail_template_id">
              @for (template of mailTemplateList; track template.id) {
              <mat-option [value]="template.id">
                {{ template.name }}
              </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        }
      </div>
      @if(!rewardInfoModel.is_external){

      <!-- Customer eligibility selection -->
      <!-- Sales channel selection -->
      <div class="detail-section section-sales-channels">
        <div class="sub-title">
          Sales Channels<span style="color: darkred">*</span>
        </div>
        <div class="sales-channel-list-container">

          @for (channel of saleChannelsReward; track saleChannelsReward) {
          <mat-checkbox [(ngModel)]="channel.selected" [disabled]="rewardInfoModel.reward_eligibility=== 1"
            (change)="onSalesChannelChange($event)">{{channel.title}}</mat-checkbox>
          }

        </div>

      </div>
      <!-- Sales channel selection -->


      <div class="detail-section">
        <div class="sub-title">
          Auto Distribution<span style="color: darkred">*</span>
        </div>
        <p class="detail-content">How you want to distribute this reward?</p>
        @if(rewardInfoModel.reward_eligibility == 1){
        <div class="checkbox-section">
          <mat-checkbox [(ngModel)]="rewardInfoModel.is_event_driven" (change)="onAutoDistributeChanged()" disabled>Auto
            distribute this reward on special event</mat-checkbox>
        </div>
        }@else{
        <div class="checkbox-section">
          <mat-checkbox [(ngModel)]="rewardInfoModel.is_event_driven" (change)="onAutoDistributeChanged()">Auto
            distribute this reward on special event</mat-checkbox>
        </div>


        <div *ngIf="rewardInfoModel.is_event_driven">
          <div class="event-container">
            <mat-form-field style="width: 80%;">
              <mat-select placeholder="Select an Event" [(ngModel)]="rewardInfoModel.crm_event_id "
                (ngModelChange)="onEventSelected($event)">
                @for (event of systemEvent; track event.id) {
                <mat-option [value]="event.id">{{ event.name }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
            <div *ngIf="selectedSystemEvent" style="display:flex;gap:20px;">
              <mat-checkbox name="autoLoad" [disabled]="selectedSystemEvent.can_override === 0" [checked]="isChecked()"
                (change)="onCheckboxChange($event)">Auto Load</mat-checkbox> <label style="margin-top: 10px;">Use
                Within</label>
              @if(rewardInfoModel.id!=0){
              <input style="width:40px; height:30px;" type="number" [(ngModel)]="rewardInfoModel.use_within"
                [disabled]="selectedSystemEvent.can_override === 0" min="1">
              }@else{
              <input style="width:40px; height:30px;" type="number" [(ngModel)]="selectedSystemEvent.use_within"
                [disabled]="selectedSystemEvent.can_override === 0" min="1">
              }
              <label style="margin-top: 10px;">Days</label>
            </div>
          </div>
        </div>
        }
      </div>



      <!--Events Section-->


      <!-- Rewad Type selection -->
      <div class="detail-section section-reward-type">
        @if(rewardInfoModel.reward_eligibility == 1){
        <div class="sub-title">Type of Offer</div>
        }@else{
        <div class="sub-title">Type of Reward</div>

        }

        @if(selectedSystemEvent){

        <mat-radio-group [(ngModel)]="rewardInfoModel.reward_type">
          <mat-radio-button [value]="5"
            *ngIf="!selectedSystemEvent.enabled_rewards || selectedSystemEvent.enabled_rewards.includes(5)">
            Free/Discounted Item based on order value
          </mat-radio-button><br />
          <div
            [ngStyle]="{'visibility': (rewardInfoModel.id == undefined || rewardInfoModel.id == 0) ? 'hidden' : 'visible'}">
            @if(rewardInfoModel.reward_eligibility == 2){
            <mat-radio-button [value]="4"
              *ngIf="!selectedSystemEvent.enabled_rewards || selectedSystemEvent.enabled_rewards.includes(4)">
              Buy X get Y
            </mat-radio-button>
            }
          </div>

        </mat-radio-group>


        }@else{
        <mat-radio-group [(ngModel)]="rewardInfoModel.reward_type">
          <!-- <mat-radio-button [value]=1 checked>Discount based on order value</mat-radio-button><br /> -->

          <mat-radio-button [value]=5>Free/Discounted Item based on order value</mat-radio-button><br />
          <!-- <mat-radio-button value="2" [disabled]="isAnyOptionSelected && selectedRewardOption !== '2'">Reward based on 
        order value</mat-radio-button><br /> S-->
          <!-- <mat-radio-button [value]=3>Item Discount</mat-radio-button><br /> -->
          <div
            [ngStyle]="{'visibility': (rewardInfoModel.id == undefined || rewardInfoModel.id == 0) ? 'hidden' : 'visible'}">
            @if(rewardInfoModel.reward_eligibility==2){<mat-radio-button [value]=4>Buy X get Y</mat-radio-button>}</div>
        </mat-radio-group>
        }


        <!-- Section For Order Based on order Value -->
        @if(rewardInfoModel.reward_type == 1){
        <div class="section-content order-value-discount-amount">

          <!-- <div *ngIf="rewardInfoModel.reward_type == 1" class="section-content order-value-discount-amount"> -->
          <div class="input-container">
            <div class="input-row">
              <label>When order value is more than </label>
              <input matInput type="number" [(ngModel)]="billDiscountByOrderValue.buy.trigger_on.amount"
                name="discountAmount"> <b>$</b>
              <label for="name">apply discount of </label>
              <input type="number" id="value" placeholder=""
                [(ngModel)]="billDiscountByOrderValue.offer.offer.variance" />
              <mat-button-toggle-group class="offer-percentage-option-grp" name="fontStyle"
                [(ngModel)]="billDiscountByOrderValue.offer.offer.is_percentage" aria-label="Font Style">
                <mat-button-toggle [value]=1>%</mat-button-toggle>
                <mat-button-toggle [value]=0>$</mat-button-toggle>
              </mat-button-toggle-group>

            </div>
          </div>
        </div>
        }@else if(rewardInfoModel.reward_type == 5){
        <!-- Section For Order Based on order Value -->

        <!-- Section For Order Based on Value get discounted/free item-->
        <div class="section-content order-value-discount-item">

          <!-- <div *ngIf="rewardInfoModel.reward_type  == 5" class="section-content order-value-discount-item"> -->
          <div class="input-container">
            <div class="input-row">
              <label>When order value is more than</label>
              <b><input type="number" id="value" [(ngModel)]="billDiscountItemModel.buy.trigger_on.amount"
                  placeholder="" required [disabled]="isDisabled()" />&nbsp;$</b>
              <label for="name"> get any one of the item below</label>
              <div class="action-btn-container">
                <button mat-flat-button class="action-btn btn-discard" (click)="removeFromBillDiscountItemModel(0)">
                  CLEAR
                </button>
                @if(selectedSystemEvent){
                @if(selectedSystemEvent.is_autoLoad === 1){
                <button mat-flat-button color="primary" (click)="openProductsDialog('')">Browse</button>
                }@else{
                <button class="pdt-button" mat-flat-button color="primary" (click)="openSelectionTypeDialog('offer')">
                  Browse
                </button>
                }
                }@else{
                <button class="pdt-button" mat-flat-button color="primary" (click)="openSelectionTypeDialog('offer')">
                  Browse
                </button>
                }
              </div>
            </div>
          </div>

          <div class="input-container">
            <div class=" offer-item-list-container">
              @if(productList){
              @for (offer of billDiscountItemModel.offer.offer; track offer.id; let i = $index) {
              <div class="input-row offer-item-container">
                <input type="number" id="value{{i}}" placeholder="" [(ngModel)]="offer.variance" required
                  [disabled]="isDisabled()" />
                <mat-button-toggle-group class="item-offer-percentage-option-grp" name="fontStyle{{i}}"
                  [(ngModel)]="offer.is_percentage">
                  <mat-button-toggle [value]="1" [checked]="true">% </mat-button-toggle>
                  <mat-button-toggle [value]="0">$</mat-button-toggle>
                </mat-button-toggle-group>
                <label>discount for </label>
                <input class="qty-input" type="number" id="myCheckbox{{i}}" [(ngModel)]="offer.qty" required readonly
                  [disabled]="isDisabled()" />
                X
                <span [ngClass]="{'duplicate-item': isDuplicateItem(offer.id)}"><b>{{ getSaleItem(offer.id).name
                    }}</b></span>
                <mat-icon class="delete-button"
                  (click)="removeFromBillDiscountItemModel(offer.id)">delete_forever</mat-icon>
              </div>
              }
              }
            </div>
          </div>
        </div>
        }@else if(rewardInfoModel.reward_type == 3){

        <div class="section-content sale-item-discount-container">
          <div style="display: flex; align-items: center; padding: 5px 0px; width: 100%;">
            <mat-label>If the order contains any of below items:</mat-label>
            <div class="action-btn-container">
              <button mat-flat-button class="action-btn btn-discard" (click)="removeFromSaleItemDiscountModel(0)">
                CLEAR
              </button>
              <button mat-flat-button class="action-btn btn-browse" (click)="openSelectionTypeDialog('buy')">
                BROWSE
              </button>
            </div>
          </div>
          <div class="block-list-container">
            <div *ngIf="rewardInfoModel.reward_type == 3 && saleItemDiscountModel.buy.trigger_on.length === 0"
              class="no-items-selected">
              <span style="text-align:center;">No items selected please select item.</span>
            </div>
            @for (trigger_on of saleItemDiscountModel.buy.trigger_on; track trigger_on.id; let i = $index) {

            <div class="block item-block">
              <span class="block-label">{{ getSaleItem(trigger_on.id).name }}</span>
              <mat-icon class="close-button"
                (click)="removeFromSaleItemDiscountModel(trigger_on.id)">delete_forever</mat-icon>
            </div>
            }
          </div>

          <div class="input-container">
            <div class="input-row">
              <label for="name">With a min quantity of</label>
              <input type="number" id="value" placeholder="" [(ngModel)]="saleItemByDiscountParam.buy.qty" />
              <label for="name">apply discount of</label>
              <input type="number" id="value" placeholder="" [(ngModel)]="saleItemDiscountModel.offer.offer.variance" />
              <mat-button-toggle-group class="item-discount-percentage-option-grp" name="fontStyle"
                aria-label="Font Style" [(ngModel)]="saleItemDiscountModel.offer.offer.is_percentage">
                <mat-button-toggle [value]=1 checked>%</mat-button-toggle>
                <mat-button-toggle [value]=0>$</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
        </div>
        }@else if(rewardInfoModel.reward_type == 4){

        <!-- Section item discount-->

        <!-- Section Buy x Get y -->

        <div
          [ngStyle]="{'visibility': (rewardInfoModel.id == undefined || rewardInfoModel.id == 0) ? 'hidden' : 'visible'}">
          @if(rewardInfoModel.reward_eligibility == 2){
          <div class="section-content buy-x-get-y-container">

            <div class="input-container">
              <div style="display: flex; align-items: center; padding: 5px 0px;" class="input-row">
                <mat-label>If the order contains any of these items with a minum quantity of </mat-label>
                <input type="number" [(ngModel)]="buyXgetYDiscountParam.buy.qty" [disabled]="isDisabled()" />
                <div class="action-btn-container">
                  <button mat-flat-button class="action-btn btn-discard"
                    (click)="removeFromBuXGetYDiscountModel('buy',0)">
                    CLEAR
                  </button>
                  <button mat-flat-button color="primary" (click)="openSelectionTypeDialog('buy')">
                    Browse
                  </button>
                </div>
              </div>
            </div>
            <div class="block-list-container">
              <div *ngIf="buyXGetYDicountModel.buy.trigger_on.length === 0" class="no-items-selected">
                <span style="text-align:center;">No items selected please select item.</span>
              </div>
              @for (product of buyXGetYDicountModel.buy.trigger_on; track product.id; let i = $index) {
              <div class="block item-block">

                <span class="block-label" [ngClass]="{'duplicate-item': isDuplicateItem(product.id)}">{{
                  getSaleItem(product.id).name }}</span>
                <mat-icon class="close-button"
                  (click)="removeFromBuXGetYDiscountModel('buy',product.id)">delete_forever</mat-icon>
              </div>
              }
            </div>

            <!-- </div> -->
            <div class="input-container">
              <div class="input-row">
                <label>then get </label>
                <input type="number" [(ngModel)]="buyXgetYDiscountParam.offer.qty" readonly [disabled]="isDisabled()" />
                <label>quantity of any of the below items</label>
                <div class="action-btn-container">
                  <button mat-flat-button class="action-btn btn-discard"
                    (click)="removeFromBuXGetYDiscountModel('get',0)">
                    CLEAR
                  </button>
                  @if(selectedSystemEvent){
                  @if(selectedSystemEvent.is_autoLoad === 1){
                  <button mat-flat-button color="primary" (click)="openProductsDialog('offer')">

                    Browse
                  </button>
                  }@else{
                  <button mat-flat-button color="primary" (click)="openSelectionTypeDialog('offer')">

                    Browse
                  </button>
                  }}@else{
                  <button mat-flat-button color="primary" (click)="openSelectionTypeDialog('offer')">

                    Browse
                  </button>
                  }
                </div>
              </div>
            </div>
            <div class="block-list-container">
              <div *ngIf="buyXGetYDicountModel.offer.offer.length === 0" class="no-items-selected">
                <span style="text-align:center;">No items selected please select item.</span>
              </div>
              @for (product of buyXGetYDicountModel.offer.offer; track product.id; let i = $index) {

              <div class="block item-block">
                <span class="block-label">{{ getSaleItem(product.id).name }}</span>
                <mat-icon class="close-button"
                  (click)="removeFromBuXGetYDiscountModel('get',product.id)">delete_forever</mat-icon>
              </div>
              }
            </div>
            <div class="input-container">
              <div class="input-row">
                <label for="name">with a discount of</label>
                <input type="number" [(ngModel)]="buyXgetYDiscountParam.offer.variance" [disabled]="isDisabled()" />
                <mat-button-toggle-group [(ngModel)]="buyXgetYDiscountParam.offer.isPercentage"
                  class="offer-percentage-option-grp" name="fontStyle" aria-label="Font Style"
                  [disabled]="isDisabled()">
                  <mat-button-toggle [value]=1>%</mat-button-toggle>
                  <mat-button-toggle [value]=0>$</mat-button-toggle>
                </mat-button-toggle-group>

              </div>
            </div>
          </div>
          }
        </div>
        }



      </div>
      <!-- Rewad Type selection -->

      <!-- Validity  -->
      <!-- <div class="detail-section">
        <div class="sub-title"></div>
        <br />
        <mat-form-field appearance="fill">
          <mat-label>Valid For</mat-label>
          <input matInput placeholder="Valid For" type="number" [(ngModel)]="rewardInfoModel.valid_for" />
        </mat-form-field>
        days
      </div> -->
      <!-- Validity  -->

      <!-- Publish  -->
      <!-- Publish -->
      <!-- <div class="detail-section" style="margin-bottom:20px;">
  <div class="sub-title">Validity of this Reward</div>
  <div class="event-date" style="margin-top:20px; gap:10px;">
    <mat-form-field class="example-full-width">
      <mat-label>Start Date</mat-label>
      <input matInput [matDatepicker]="picker" placeholder="Choose a date" [(ngModel)]="publishOn"
        [min]="currentDate" [ngClass]="{'is-invalid': !isDateInFuture()}" />
    
    
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    

    <div style="display: flex; gap: 10px; align-items: center;">
      <mat-form-field appearance="fill" style="width:80px;">
        <mat-label>Days</mat-label>
        <input matInput placeholder="Valid For" type="number" [(ngModel)]="rewardInfoModel.valid_for" />
      </mat-form-field>
@if(publishOn){
      <mat-form-field class="">
        <mat-label>End Date</mat-label>
        <input matInput formControlName="enddate" [value]="calculateEndDate() | date:'dd-MM-yyyy'" readonly />
      </mat-form-field>
    
  }@else{
    <mat-form-field class="">
      <mat-label>End Date</mat-label>
      <input matInput formControlName="enddate"  readonly />
    </mat-form-field>
  }
</div>

    <div *ngIf="!isDateInFuture()" class="text-danger" style="color:red;">
    </div>
  </div>
</div> -->

      }
      <div class="detail-section" style="margin-bottom: 20px;">
        @if(rewardInfoModel.reward_eligibility == 1){

        <div class="sub-title">Validity of this Offer</div>
        }@else{
        <div class="sub-title">Validity of this Reward</div>

        }
        <div class="event-date" style="margin-top: 20px; gap: 10px;">
          <mat-form-field class="example-full-width">
            <mat-label>Start Date</mat-label>
            <input matInput [matDatepicker]="picker" placeholder="Choose a date" [(ngModel)]="publishOn"
              [min]="currentDate" [ngClass]="{'is-invalid': !isDateInFuture()}" (dateChange)="calculateEndDate()" />

            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <!-- Dropdown for Validity Options -->
          <mat-form-field class="example-full-width">
            <mat-label>Validity</mat-label>
            <mat-select [(ngModel)]="rewardInfoModel.validity_type">
              <mat-option [value]=0>Valid For Ever</mat-option>
              <mat-option [value]=1>Valid Untill</mat-option>
              <mat-option [value]=2>Valid For</mat-option>

            </mat-select>
          </mat-form-field>

          <!-- Only show if "Number of days" is selected -->
          @if(rewardInfoModel.validity_type === 2){
          <div style="display: flex; gap: 10px; align-items: center;">
            <mat-form-field appearance="fill" style="width: 80px;">
              <mat-label>Days</mat-label>
              <input matInput placeholder="Valid For" type="number" [(ngModel)]="rewardInfoModel.valid_for"
                (ngModelChange)="calculateEndDate()" min="1" />
            </mat-form-field>
          </div>
          }@else if(rewardInfoModel.validity_type === 1){

          <!-- Only show if "End Date" is selected -->
          <div style="display: flex; gap: 10px; align-items: center;">
            <mat-form-field class="example-full-width">
              <mat-label>Date</mat-label>
              <input matInput [matDatepicker]="endDatePicker" placeholder="End Date" [(ngModel)]="endDate"
                (dateChange)="calculateValidFor()" [min]="publishOn" [ngClass]="{'is-invalid': !isDateInFuture()}" />

              <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
          }@else{

          }

        </div>


      </div>